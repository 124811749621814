import React from 'react'
import Button from '../elements/Button'

const ClientHeader = () => {


    return (
        <>
            <section className="text-gray-600 body-font bg-white">
                <div className="container mx-auto flex px-5 pt-8 lg:flex-row flex-col items-center">
                    <div className={`lg:flex-grow lg:w-1/2  flex flex-col lg:items-start lg:text-left lg:pl-24`}>
                        {/* <div className="w-16 border-b-4 border-gray-200 mb-4"></div> */}
                        <small className='text-gray-400'>Current Clients</small>
                        <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gold-400">Client Access Portal</h1>
                        <p className="mb-8 leading-relaxed"></p>

                        {/* <div className="flex">
                            {links.map((link) => (
                                <Button path={link.path} style="primary">{link.name}</Button>
                            ))}
                        </div> */}

                    </div>
                    <div className='relative lg:max-w-lg lg:w-full w-5/6 order-first mb-16 md:mb-0 hidden lg:flex'>
                        <img className="grayscale object-cover object-center rounded-t-2xl shadow-md h-96" alt="hero" src={`https://picsum.photos/id/9/1000/600`} />
                        <div className="absolute inset-0 bg-blue-500/75 rounded-t-2xl"></div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ClientHeader